import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";

const initialFieldValues = {
    markUpId: "00000000-0000-0000-0000-000000000000",
    travelType: "DOMESTIC",
    flightCommission: 0,
    hotelCommission: 0,
    paymentGateway: 0,
    comissionType:"AMOUNT"
};
export default function Markup() {
    const [lists, setLists] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const validate = () => {
        let temp = {};
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("markUpId", values.markUpId)
            formData.append("travelType", values.travelType)
            formData.append("flightCommission", values.flightCommission)
            formData.append("hotelCommission", values.hotelCommission)
            formData.append("paymentGateway", values.paymentGateway)
            formData.append("comissionType", values.comissionType)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEMARKUP, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEMARKUP, updateRecord, { ...headerconfig })
        };
    };
    const checkRecordExists = (travelType) => {
        var rr = lists.find((item) => item.travelType === travelType);
        if (rr == null) {
            return true;
        }
        else {
            return false;
        }
    };
    const addOrEdit = (formData) => {
        if (checkRecordExists(formData.get('travelType'))) {
            if (formData.get('markUpId') === "00000000-0000-0000-0000-000000000000") {
                applicationAPI()
                    .create(formData)
                    .then((res) => {
                        if (res.data.statusCode === 200) {
                            handleSuccess("Record Created");
                            resetForm();
                            GetData();
                            setBtnSubmit(true);
                        }
                        else {
                            handleError(res.data.message);
                            setBtnSubmit(true);
                        }
                    })
                    .catch(function (error) {
                        handleError("Error in saving data");
                        setBtnSubmit(true);
                    });
            }
        }
        else if (formData.get('markUpId') !== "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetData();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
        }
        else {
            setBtnSubmit(true);
            resetForm();
            alert(formData.get('travelType') + ' Record already exists. Please edit')
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetData = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETMARKUPS, { ...headerconfig })
            .then((response) => {
                console.log(response.data.data)
                if (response.data.data.succeeded) {
                    setLists(response.data.data.data);
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetData();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Markups</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Type</label>
                                            <select name="travelType" value={values.travelType} onChange={handleInputChange} className={"form-select" + applyErrorClass('travelType')}>
                                                <option value="DOMESTIC">DOMESTIC</option>
                                                <option value="INTERNATIONAL">INTERNATIONAL</option>
                                            </select>
                                            {errors.travelType === false ? (<div className="validationerror">Please enter type </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="hotelCommission" className="form-label">Hotel Commission</label>
                                            <input type="number" value={values.hotelCommission} name="hotelCommission" onChange={handleInputChange} className={"form-control" + applyErrorClass('hotelCommission')} placeholder="Please Enter hotelCommission" />
                                            {errors.hotelCommission === false ? (<div className="validationerror">Please enter hotelCommission </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="flightCommission" className="form-label">Flight Commission</label>
                                            <input type="number" value={values.flightCommission} name="flightCommission" onChange={handleInputChange} className={"form-control" + applyErrorClass('flightCommission')} placeholder="Please Enter flightCommission" />
                                            {errors.flightCommission === false ? (<div className="validationerror">Please enter flightCommission </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="comissionType" className="form-label">Commission Type</label>
                                            <select name="comissionType" value={values.comissionType} onChange={handleInputChange} className={"form-select" + applyErrorClass('comissionType')}>
                                                <option value="AMOUNT">AMOUNT</option>
                                                <option value="PERCENTAGE">PERCENTAGE</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary w-100">{values.markUpId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Markups</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Type</th>
                                                        <th>Flight Commission</th>
                                                        <th>Hotel Commission</th>
                                                        <th>Commission Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {lists.length > 0 && lists.map((v, index) =>
                                                        <tr key={v.markUpId}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.travelType}</td>
                                                            <td>{v.flightCommission} {v.comissionType==="PERCENTAGE"? "%": ""}</td>
                                                            <td>{v.hotelCommission} {v.comissionType==="PERCENTAGE"? "%": ""}</td>
                                                            <td>{v.comissionType}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(v)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}