import { Routes, Route } from 'react-router-dom';
import Login from './Authentication/Login';
import Logout from './Authentication/Logout';
import Supplier from './Components/Supplier/Supplier';
import SADashboard from './Components/Dashboards/SADashboard';
import SupplierContact from './Components/Supplier/SupplierContact';
import Customers from './Components/Customers/Customer';
import FlightBookings from './Components/Flights/FlightBookings';
import FlightBookingDetails from './Components/Flights/FlightBookingDetails';
import VisaRequestDetails from './Components/Visa/VisaRequestDetails';
import FLightReport from './Components/Reports/FlightReport';
import Users from './Components/Masters/Users';
import SendWhatsApp from './Components/Customers/SendWhatsApp';
import SendSMS from './Components/Customers/SendSMS';
import GSTReport from './Components/Reports/GSTReport';
import HotelBookings from './Components/Hotels/HotelBookings';
import HotelBookingDetails from './Components/Hotels/HotelBookingDetails';
import Company from './Components/Masters/Company';
import HotelReport from './Components/Reports/HotelReport';
import Agents from './Components/Agents/Agents';
import Visas from './Components/Visa/Visas';
import VisaRequests from './Components/Visa/VisaRequests';
import HolidayDestination from './Components/Holidays/HolidayDestination';
import HolidayItinerary from './Components/Holidays/HolidayItinerary';
import VisaPrice from './Components/Visa/VisaPrice';
import HolidayEnquiry from './Components/Holidays/HolidayEnquiry';
import PackageEnquiry from './Components/Holidays/PackageEnquiry';
import PackageInclusion from './Components/Holidays/PackageInclusion';
import PackageExclusion from './Components/Holidays/PackageExclusion';
import StudyEnquiry from './Components/Study/StudyEnquiry';
import StudyContent from './Components/Study/StudyContent';
import StudyDestination from './Components/Study/StudyDestinations';
import Tours from './Components/Tours/Tours';
import Destinations from './Components/Tours/Destinations';
import Enquiry from './Components/Tours/Enquiry';
import CreateTour from './Components/Tours/CreateTour';
import ViewTour from './Components/Tours/ViewTour';
import PackageDetails from './Components/Holidays/PackageDetails';
import Packages from './Components/Holidays/Packages';
import Package from './Components/Holidays/Package';
import PromoCode from './Components/Masters/PromoCode';
import AgentCredits from './Components/Agents/AgentCredits';
import Markup from './Components/Masters/Markup';
import EditTour from './Components/Tours/EditTour';
import PaymentGateway from './Components/Masters/PaymentGateway';
import AgentWallets from './Components/Agents/AgentWallets';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />        
        <Route path="/dashboard" element={<SADashboard />} />
        <Route path="/suppliers" element={<Supplier />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/suppliercontact" element={<SupplierContact />} />
        <Route path="/sendwhatsapp" element={<SendWhatsApp />} />
        <Route path="/sendsms" element={<SendSMS />} />
        <Route path="/flightbookings" element={<FlightBookings />} />
        <Route path="/flightbookingdetails/:flightBookingId" element={<FlightBookingDetails />} />
        <Route path="/hotelbookings" element={<HotelBookings />} />
        <Route path="/hotelbookingdetails/:hotelBookingId" element={<HotelBookingDetails />} />

        <Route path="/flight-report" element={<FLightReport />} />
        <Route path="/hotel-report" element={<HotelReport />} />
        <Route path="/company" element={<Company />} />
        <Route path="/users" element={<Users />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/agentcredits" element={<AgentCredits />} />
        <Route path="/agentwallets" element={<AgentWallets />} />
        <Route path="/promocodes" element={<PromoCode />} />
        <Route path="/markups" element={<Markup />} />

        <Route path="/holidayenquiry" element={<HolidayEnquiry />} />
        <Route path="/packageenquiry" element={<PackageEnquiry />} />
        <Route path="/holidaydestinations" element={<HolidayDestination />} />
        <Route path="/packages/:destinationId?" element={<Packages />} />
        <Route path="/package" element={<Package />} />
        <Route path="/packagedetails/:packageId?" element={<PackageDetails />} />
        <Route path="/packageinclusion/:packageId?" element={<PackageInclusion />} />
        <Route path="/packageexclusion/:packageId?" element={<PackageExclusion />} />
        <Route path="/holidayitineraries" element={<HolidayItinerary />} />
        <Route path="/tours" element={<Tours />} />
        
        <Route path="/destinations" element={<Destinations />} />
        <Route path="/tourenquiry" element={<Enquiry />} />
        <Route path="/createtour/:destinationId?" element={<CreateTour />} />
        <Route path="/tour/:tourId" element={<ViewTour />} />
        <Route path="/edit-tour/:tourId" element={<EditTour />} />

        <Route path="/Studyenquiry" element={<StudyEnquiry />} />
        <Route path="/studydestinations" element={<StudyDestination />} />
        <Route path="/Studycontent" element={<StudyContent />} />

        <Route path="/visarequests" element={<VisaRequests />} />
        <Route path="/visarequestdetails/:visaRequestId" element={<VisaRequestDetails />} />
        <Route path="/visaprices" element={<VisaPrice />} />
        <Route path="/visas" element={<Visas />} />

        <Route path="/paymentgateway" element={<PaymentGateway />} />
      </Routes>
    </div>
  );
}

export default App;
